/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 - 2025 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

export const DELL_APPS_API_ENDPOINTS = {
  // eslint-disable-next-line no-undef
  DELL_APPS_BASE: `${process.env.REACT_APP_API}/dellApps`,
  // eslint-disable-next-line no-undef
  DELL_APPS_MANIFEST: `${process.env.REACT_APP_API}/dellApps?app_model=all`,
};

export const DELL_APPS_CONSTANTS = {
  // String similarity threshold constant for app name comparison
  DELL_APP_NAME_SIMILARITY_THRESHOLD: 0.6,
};

export const FETCH_APPS_DETAILS = {
  // Delay duration to fetch intune apps after publishing
  FETCH_INTUNE_APPS_DELAY: 4000,
  // Max retries to fetch intune apps
  MAX_RETRIES: 5,
};

export const MODULE_TYPES = {
  MODULE_TYPE_UPDATE: "update",
  MODULE_TYPE_SECURITY: "security",
  MODULE_TYPE_TELEMETRY: "telemetry",
};

export const SCREEN_NAMES = {
  LICENSE_AGREEMENT: "licenseAgreement",
  PUBLISHING: "publishing",
  PUBLISHED: "published",
};

export const MODULE_NAMES = {
  DCDMU: "Dell device updates",
  DCDMS: "Dell Built-in Security",
};

export const MODULE_INTERNAL_NAMES = {
  DCDMU: "DCDMU",
  DCDMS: "DCDMS",
};

export const APP_STATUS_ON_DEVICE_DETAILS = {
  INSTALL: "installed",
  UNINSTALL: "uninstall",
  UNKNOWN: "unknown",
};

export const UNKNOWN_APPLICATION = "Unknown Application";

export const JSON_SCHEMA_URL = "http://json-schema.org/draft-07/schema#";

export const GRAPH_API_URLS = {
  MOBILE_APPS_BETA:
    "https://graph.microsoft.com/beta/deviceAppManagement/mobileApps",
  MOBILE_APPS_V1:
    "https://graph.microsoft.com/v1.0/deviceAppManagement/mobileApps",
  RECOVERY_KEYS:
    "https://graph.microsoft.com/v1.0/informationProtection/bitlocker/recoveryKeys",
  USERS_V1: "https://graph.microsoft.com/v1.0/users",
  USERS_BETA: "https://graph.microsoft.com/beta/users",
  HARDWARE_PASSWORD_DETAILS_BETA:
    "https://graph.microsoft.com/beta/deviceManagement/hardwarePasswordDetails",
  DEVICES_V1:
    "https://graph.microsoft.com/v1.0/deviceManagement/managedDevices",
  DEVICES_BETA:
    "https://graph.microsoft.com/beta/deviceManagement/manageddevices",
  DIRECTORY_OBJECTS_V1:
    "https://graph.microsoft.com/v1.0/directoryObjects/microsoft.graph.getByIds",
};

export const INTUNE_APPS_URL =
  "https://endpoint.microsoft.com/#view/Microsoft_Intune_Apps/SettingsMenu/appId";

export const DELL_URLS = {
  ABOUT: "https://www.dell.com",
  SUPPORT: "https://www.dell.com/support",
  PRIVACY_US: "https://www.dell.com/learn/us/en/uscorp1/policies-privacy",
  COPYRIGHT:
    "https://www.dell.com/learn/us/en/uscorp1/site-terms-of-use-copyright",
  LICENSE_AGREEMENT:
    "https://www.dell.com/en-us/lp/legal/art-software-license-agreements",
  TECH_DIRECT_HOME: "https://tdm.dell.com/portal",
  DEVICE_MANAGEMENT_PORTAL:
    "https://int1-portal.amds.dell.com/ccm-web/diauth/entraid",
  PRIVACY_HOME: "https://www.dell.com/privacy",
  TELEMETRY_DCDM: "https://www.dell.com/support/kbdoc/000256140",
};

// The SPECIAL_CHARACTER_REGEX is used to find special characters in regular expressions.
// Special Characters in scope: . * + ? ^ $ { } ( ) | [ ] \
// APPS_NAME_WITH_DATE_TIME is a regex for matching dell apps names with a date and time appended.
// DATE_TIME_FORMAT is a regex for matching the date and time format.
// BACKSLASH_REPLACE_REGEX is used to replace special characters with a backslash.
// APP_NAME_PIPELINE_WITH_DATE_TIME is a regex for matching dell apps names with a date and time appended in a pipeline and also it supported DTD(Dell Trusted Device) format.

export const APPS_REGEX_FORMAT = {
  FIND_SPECIAL_CHARACTERS: /[.*+?^${}()|[\]\\]/g,
  APP_NAME_WITH_DATE_TIME: "/^(.*?)d{2}.d{2}.d{2}_d{2}:d{2}_UTC$/",
  DATE_TIME_FORMAT: "\\d{2}\\.\\d{2}\\.\\d{2}_\\d{2}:\\d{2}_UTC$",
  APPEND_BACKSLASH_INFRONT: "\\$&",
  APP_NAME_PIPELINE_WITH_DATE_TIME:
    /^.*?\s*\|?\s*.*?\d{2}\.\d{2}\.\d{2}_\d{2}:\d{2}_UTC$/g,
};
